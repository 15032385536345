<script setup lang='ts'>
import { APP_ROUTER, COMPANY_ROUTER, getHostPagePath, imageTransform } from '@qctsw/common'
import { checkRouteIsActive } from '@/shared/function'
import { NuxtLink } from '#components'
import { logicLogout } from '@/logic/user/login'

function renderOptions(items: { name: string, path: string }[]) {
  return items.map(item => ({
    key: item.path,
    type: 'render',
    render: () => h('div', { class: 'px-1' }, [
      h(NuxtLink, { class: 'dropdown-item', href: item.path }, { default: () => item.name }),
    ]),
  }))
}
const newsOptions = renderOptions([
  {
    name: '资讯分类',
    path: APP_ROUTER.newsClass(),
  },
  {
    name: '合作媒体',
    path: APP_ROUTER.newsMedia(),
  },
])

const complaintOptions = renderOptions([
  {
    name: '搜索投诉',
    path: '/complaint/search',
  },
  {
    name: '所有投诉',
    path: '/complaint/list',
  },
  {
    name: '集体投诉',
    path: '/complaint/list?type=1',
  },
  {
    name: '多次投诉',
    path: '/complaint/list?type=2',
  },
  {
    name: '一追到底',
    path: '/complaint/list?type=3',
  },
])

const { showLoginPopup } = useLoginPopupEvent()
const { IS_LOGIN, USER_HEAD, USER_NAME } = storeToRefs(useAuth())
</script>

<template>
  <header :class="[$route.name === 'search' ? '' : 'shadow-sm']" class="sticky top-0 left-0  bg-white z-999">
    <div class="bg-slate-8 text-white">
      <div class="public-container flex items-center justify-between text-3.5 h-2em">
        <h1 class="text-3 tracking-0.5 pl-25 flex-shrink-0">
          专业的汽车质量投诉平台
        </h1>
        <LayoutHeaderComplaint />
      </div>
    </div>
    <div class="relative public-container h-15 flex items-center justify-between">
      <NuxtLink :to="APP_ROUTER.home()">
        <img class="h-12 block object-contain text-0 flex-shrink-0 bg-transparent" src="/logo.png">
      </NuxtLink>
      <div class="flex justify-between flex-grow min-w-0 h-full">
        <slot name="nav">
          <nav class="nav ml-12.5">
            <NuxtLink :to="APP_ROUTER.home()">
              首页
            </NuxtLink>
            <NDropdown trigger="hover" display-directive="show" :options="newsOptions">
              <NuxtLink :to="APP_ROUTER.news()" :class="{ 'page-active': checkRouteIsActive(/^\/news/) }">
                资讯
              </NuxtLink>
            </NDropdown>
            <NDropdown trigger="hover" display-directive="show" :options="complaintOptions">
              <NuxtLink :to="APP_ROUTER.complaint()" :class="{ 'page-active': checkRouteIsActive(/^\/complaint/) }">
                投诉
              </NuxtLink>
            </NDropdown>

            <NuxtLink :to="APP_ROUTER.reputation()" :class="{ 'page-active': checkRouteIsActive(/^\/reputation/) }">
              口碑
            </NuxtLink>
            <NuxtLink :to="APP_ROUTER.redAndBlackList()">
              红黑榜
            </NuxtLink>
            <NuxtLink :to="APP_ROUTER.publish()">
              发表投诉
            </NuxtLink>
          </nav>
        </slot>
        <div class="flex items-center justify-between">
          <div class="flex items-center justify-between text-4  ">
            <NuxtLink v-show="$route.name !== 'search'" to="/search">
              <i class="i-ep:search text-6.25" />
            </NuxtLink>
            <ClientOnly>
              <template v-if="!IS_LOGIN">
                <LayoutLoginPopup />
                <button class="text-blue-5 mx-sm" @click="showLoginPopup('phone')">
                  个人登录
                </button>
                <NuxtLink class="text-blue-5 relative" target="_blank" :to="getHostPagePath('company', COMPANY_ROUTER.login())">
                  企业登录
                </NuxtLink>
              </template>
              <template v-else>
                <div class="flex items-center justify-evenly  ml-sm flex-grow">
                  <UtilNotification />
                  <NPopover trigger="hover" placement="bottom-start">
                    <template #trigger>
                      <NuxtLink :to="APP_ROUTER.userInfo()" class="flex items-center m-sm flex-shrink-0">
                        <NAvatar v-if="USER_HEAD" :size="40" round color="#2080f0" :src="`${imageTransform(USER_HEAD, { width: 100, height: 100 })}`" />
                        <span v-else class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-blue-5 text-white text-5 font-bold">
                          {{ USER_NAME?.slice(0, 1) || "无" }}
                        </span>
                      </NuxtLink>
                    </template>
                    <h3 class="font-bold pb-2 w-20 text-center truncate border-b-1 border-slate-3" :title="USER_NAME">
                      {{ USER_NAME }}
                    </h3>
                    <NuxtLink class="pt-2 text-center block" :to="APP_ROUTER.userInfo()">
                      账号管理
                    </NuxtLink>
                    <button class="py-3 cursor-pointer text-center block w-full" type="button" @click="logicLogout()">
                      退出
                    </button>
                  </NPopover>
                </div>
              </template>
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang='scss' scoped>
.nav {
  --uno: flex items-center justify-center text-4.5 pr-8 overflow-x-auto h-full;

  & > a {
    --uno: px-xl py-2 mr-sm tracking-0.5 text-5;
  }

  .page-active,
  [aria-current="page"],
  a:hover {
    box-shadow: inset 0 5px 5px 0 rgb(0 0 0 / 10%);

    --uno: text-white  rounded bg-blue-5;
  }
}
</style>
